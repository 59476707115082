import React, { FC, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import Logo from '../../components/Logo';
import ZappiLogo from '../../assets/logos/Zappy_logo_horizontal01.png';
import AuthContext from '../../contexts/authContext';

interface IBrandProps {
	asideStatus: boolean;
	setAsideStatus(...args: unknown[]): unknown;
}

const Brand: FC<IBrandProps> = ({ asideStatus, setAsideStatus }) => {
	const { userData } = useContext(AuthContext);

	const handleLogoClick = () => {
		if (userData?.rolId === 1) {
			localStorage.removeItem('institutionResponse');
			console.log("institutionResponse eliminado del localStorage");
		} else {
			console.log("No se eliminó institutionResponse debido a rolId diferente de 1");
		}
	};

	return (
		<div className='brand'>
			<div className='brand-logo'>
				<h1 className='brand-title '>
					<Link
						to={userData?.rolId === 1 ? '/' : '/institution-detail'}
						aria-label='Logo'
						onClick={handleLogoClick}
					>
						<img src={ZappiLogo} alt="Zappi Logo" width={140} />
					</Link>
				</h1>
			</div>
			<button
				type='button'
				className='btn brand-aside-toggle'
				aria-label='Toggle Aside'
				onClick={() => setAsideStatus(!asideStatus)}
			>
				<Icon icon='FirstPage' className='brand-aside-toggle-close' />
				<Icon icon='LastPage' className='brand-aside-toggle-open' />
			</button>
		</div>
	);
};

Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
